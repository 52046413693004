<template>
  <div>forgot password</div>
</template>

<script>
export default {
  name: 'ForgotPassword',
}
</script>

<style lang="scss" scoped></style>
